import React, { useState } from 'react'
import './ProjectPage.css';

/* Modal */
import Modal from "./Modal";

/* React router */
import { NavLink } from 'react-router-dom';

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */
const proyectsImg = require.context('../../img', true);

const ProjectGame = () => {
    const [estadoModal4, cambiarEstadoModal4] = useState(false);
    const [estadoModal3, cambiarEstadoModal3] = useState(false);
    const [estadoModal2, cambiarEstadoModal2] = useState(false);
    const [estadoModal1, cambiarEstadoModal1] = useState(false);


    return (
        <div>
        /*
            <HeaderPage />

            <ParticleBackground />

           <main>
                <section className="proyectos mas-proyect" id="proyectos">
                    <h1 className="heading">
                        <FormattedMessage
                            id='projects'
                            defaultMessage='Projects'
                        />
                    </h1>
                    <nav className="navbar nav-proj">
                        <NavLink to="/project/" offset={-150} duration={500}>
                            <FormattedMessage
                                id='site-web'
                                defaultMessage='React.Js'
                            />
                        </NavLink>
                        <NavLink to="/project/app" offset={-150} duration={500}>
                            Java BackEnd
                        </NavLink>
                        <NavLink to="/project/game" offset={-150} duration={500}>
                            <FormattedMessage
                                id='games'
                                defaultMessage='C# (Asp.Net)'
                            />
                        </NavLink>
                    </nav>
                </section>

                <section className="projects__grid games">
                    <div className="projects__item">
                        <a onClick={() => cambiarEstadoModal4(!estadoModal4)}>
                            <img src={proyectsImg(`./backend_asp.net_mvc.jpeg`)} alt="" className="projects__img" />
                        </a>
                    </div>
                    
                </section>
            </main>

            <Modal
                estado={estadoModal4}
                cambiarEstado={cambiarEstadoModal4}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImg(`./backend_asp.net_mvc.jpeg`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                Books
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsGame-info-4-p2'
                                    defaultMessage="The website enables interactive experiences, allowing users to search, review, rate, and share books. It also serves as a showcase for authors and publishers to promote their works. The ultimate goal of the Books website is to create a community that supports and encourages a passion for reading."
                                />
                            </p>
                            <div className="eins-modal-text-2">
                                <span></span> <a href="" target="_blank"></a>
                            </div>
                            <div className="eins-modal-text-3">
                                <span>
                                    <FormattedMessage
                                        id='projects-tec'
                                        defaultMessage='Used technology:'
                                    />
                                </span>
                                <div className="eins-modal-tec">
                                    <img src="https://seeklogo.com/images/C/c-logo-A44DB3D53C-seeklogo.com.png" alt="C#" />
                                    <img src="https://logodix.com/logo/1796970.png" alt="Asp.Net" />
                                    <img src="https://uploads-ssl.webflow.com/62d5695206896eeec18fc636/63e6a9c0ac8f176d1d339210_sql-logo.png" alt="Microsoft SQL Server" />
                                    <img src="https://www.svgrepo.com/show/374111/swagger.svg" alt="Swagger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

           

            <ScrollToTop />

            <Footer />
          */
        </div>
    )
}
export default ProjectGame;

import React, {useState} from 'react';
import MensajeEnglish from './../language/en.json';
import MensajeTurkey from './../language/tr.json';
import MensajeGermany from './../language/de.json';
import MensajeFransa from './../language/fr.json';
import MensajeRusia from './../language/ru.json';
import MensajeChinese from './../language/zh.json';
import MensajeJapanese from './../language/ja.json';
import MensajeKorean from './../language/kr.json';
import MensajeSpanish from './../language/es.json';
import {IntlProvider} from 'react-intl';

const langContext = React.createContext();


const LangProvider = ({children}) => {
    let localePorDefecto;
	let mensajesPorDefecto;
	const lang = localStorage.getItem('lang');

	if(lang){
		localePorDefecto = lang

		if(lang === 'tr-TR'){
			mensajesPorDefecto = MensajeTurkey;
        }else if(lang === 'fr-FR'){
                mensajesPorDefecto = MensajeFransa;
        }else if(lang === 'ru-RU'){
                mensajesPorDefecto = MensajeRusia;
		}else if(lang === 'de-DE'){
                mensajesPorDefecto = MensajeGermany;
        }else if(lang === 'zh-ZH'){
                mensajesPorDefecto = MensajeChinese;
        }else if(lang === 'ja-JA'){
                mensajesPorDefecto = MensajeJapanese;
        }else if(lang === 'kr-KR'){
                mensajesPorDefecto = MensajeKorean;
        }else if(lang === 'es-ES'){
                mensajesPorDefecto = MensajeSpanish
        }else if(lang === 'en-US'){
			mensajesPorDefecto = MensajeEnglish;
		} else {
			localePorDefecto = 'en-US'
			mensajesPorDefecto = MensajeEnglish;
		}
	}

    const [mensaje, setMensaje] = useState(mensajesPorDefecto);
    const [locale, setLocale] = useState(localePorDefecto);

    const selectLanguage = (language) =>{
        switch (language) {
            case 'es-ES':
                setMensaje(MensajeSpanish);
                setLocale('es-ES');
                localStorage.setItem('lang', 'es-ES');
                break;
            case 'kr-KR':
                setMensaje(MensajeKorean);
                setLocale('kr-KR');
                localStorage.setItem('lang', 'kr-KR');
                break;
            case 'ja-JA':
                setMensaje(MensajeJapanese);
                setLocale('ja-JA');
                localStorage.setItem('lang', 'ja-JA');
                break;
            case 'zh-ZH':
                setMensaje(MensajeChinese);
                setLocale('zh-ZH');
                localStorage.setItem('lang', 'zh-ZH');
                break;
            case 'fr-FR':
                setMensaje(MensajeFransa);
                setLocale('fr-FR');
                localStorage.setItem('lang', 'fr-FR');
                break;
            case 'ru-RU':
                setMensaje(MensajeRusia);
                setLocale('ru-RU');
                localStorage.setItem('lang', 'ru-RU');
                break;
            case 'de-DE':
                setMensaje(MensajeGermany);
                setLocale('de-DE');
                localStorage.setItem('lang', 'de-DE');
                break;
            case 'tr-TR':
                setMensaje(MensajeTurkey);
                setLocale('tr-TR');
                localStorage.setItem('lang', 'tr-TR');
                break;
            case 'en-US':
                setMensaje(MensajeEnglish);
                setLocale('en-US');
                localStorage.setItem('lang', 'en-US');
                break;
            default:
                setMensaje(MensajeEnglish);
                setLocale('en-US');
                localStorage.setItem('lang', 'en-US');
        }
    }
    return (
        <langContext.Provider value={{selectLanguage: selectLanguage}}>
            <IntlProvider locale={locale} messages={mensaje}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
};

export {LangProvider, langContext};
import React from 'react';
import '../../pages/Project/ProjectPage.css'
import { Link } from 'react-router-dom';
import { ButtomGet } from '../ButtomGet/ButtomGet';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

/* Img */
const proyectImg = require.context('../../img', true);

const Project = () => {
    return (
        <section className="proyectos" id="proyectos">
            <h2 className="heading">
                <FormattedMessage
                    id='projects'
                    defaultMessage='Projects'
                />
            </h2>
            <div className="proyect-site" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <Swiper
                    spaceBetween={30}
                    loop={true}
                    grabCursor={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    className='proyectos-slider mySwiper'
                >
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./lestrentalcar.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>Let's Rental Car</h3>
                            <p>
                            User: Database integration was achieved using Hibernate JPA, and dependencies were minimized by adhering to SOLID principles and applying abstraction. 
                            ImageFile: Dependency Injection was implemented using Spring Boot to manage dependencies effectively. ContactMessage: Communication messages were stored in the database using Spring Boot with JPA, and dependency management was handled with Maven. 
                            ReservationController: An API controller was created using Spring Boot, and authorization was strengthened with Spring Security. 
                            Category: Categories and subcategories were stored in the database using Hibernate JPA, and dependency management was facilitated through Dependency Injection.
                            </p>
                            <p className="tecnologias">
                                React
                                <span> -</span> CSS
                                <span> -</span> Redux
                                <span> -</span> Bootstrap
                                <span> -</span> PostgreSQL
                                <span> -</span> Solidity
                                <span> -</span> Java
                                <span> -</span> MongoDB
                            </p>
                            <a href="https://www.letsrentalcar.com/" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Repository</a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./lestrentalcarbackend.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>Let's Rental Car BackEnd</h3>
                            <p>
                            User: Database integration was achieved using Hibernate JPA, and dependencies were minimized by adhering to SOLID principles and applying abstraction. 
                            ImageFile: Dependency Injection was implemented using Spring Boot to manage dependencies effectively. ContactMessage: Communication messages were stored in the database using Spring Boot with JPA, and dependency management was handled with Maven. 
                            ReservationController: An API controller was created using Spring Boot, and authorization was strengthened with Spring Security. 
                            Category: Categories and subcategories were stored in the database using Hibernate JPA, and dependency management was facilitated through Dependency Injection.
                            </p>
                            <p className="tecnologias">
                                <span> -</span> Java
                                <span> -</span> PostgreSQL
                             
                            </p>
                            <a href="https://www.letsrentalcar.com/" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Repository</a>
                        </div>
                    </SwiperSlide>
             
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./gpm_frontend.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>Gaming Pro Market</h3>
                            <p>
                            User: Processing and storage of user data have been achieved using Spring Boot, Hibernate ORM, and JPA in accordance with SOLID principles. 
                            ImageFile: Storage and processing of image files have been implemented using the AGILE approach, integrating Amazon S3 cloud storage and Spring Boot MultipartFile class. 
                            ContactMessage: Management of user communication messages has been facilitated using Spring Boot, Hibernate ORM, and JPA. 
                            Spring Security: Authentication and authorization processes have been implemented using Spring Security, following the AGILE principles, and based on JSON Web Token (JWT). 
                            Category: Storage and management of category data have been accomplished by utilizing Hibernate ORM and JPA, and applying CRUD operations with Spring Boot.
                            </p>
                            <p className="tecnologias">
                                React
                                <span> -</span> CSS
                                <span> -</span> Redux
                                <span> -</span> Java
                                <span> -</span> ExpressJS
                                <span> -</span> Sequelize
                                <span> -</span> PostgreSQL
                            </p>
                            <a href="https://www.gamingpromarket.com/" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Repository</a>
                        </div>
                    </SwiperSlide> <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./gpm.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>Gaming Pro Market BackEnd</h3>
                            <p>
                            User: Processing and storage of user data have been achieved using Spring Boot, Hibernate ORM, and JPA in accordance with SOLID principles. 
                            ImageFile: Storage and processing of image files have been implemented using the AGILE approach, integrating Amazon S3 cloud storage and Spring Boot MultipartFile class. 
                            ContactMessage: Management of user communication messages has been facilitated using Spring Boot, Hibernate ORM, and JPA. 
                            Spring Security: Authentication and authorization processes have been implemented using Spring Security, following the AGILE principles, and based on JSON Web Token (JWT). 
                            Category: Storage and management of category data have been accomplished by utilizing Hibernate ORM and JPA, and applying CRUD operations with Spring Boot.
                            </p>
                            <p className="tecnologias">
                                <span> -</span> Java
                                <span> -</span> PostgreSQL
                            </p>
                            <a href="https://www.gamingpromarket.com/" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Repository</a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./myarena.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>My Arena</h3>
                            <p>
                            Managed user data using Spring Boot, Hibernate JPA, and Maven while implementing dependency injection and adhering to SOLID principles.
                            Accomplished image file management and mapping operations with Spring Boot, Hibernate JPA, and MapStruct. 
                            Enabled contact message handling and database operations for communication messages using Spring Boot and Hibernate JPA.
                            Integrated authentication and authorization processes using Spring Security. 
                            Implemented a dynamic error handling mechanism and managed potential errors using an enum structure. 
                            Added the Swagger interface for API testing and automatically generated API documentation. Developed a dedicated Controller for managing reservation operations. 
                            Managed category data through Spring Boot, Hibernate JPA, and Maven, providing CRUD operations and establishing a hierarchical structure in the database.
                            </p>
                            <p className="tecnologias">
                                
                                <span> -</span> Java
                                <span> -</span> PostgreSQL
                            </p>
                            <a href="https://www.gamingpromarket.com/" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Repository</a>
                        </div>
                    </SwiperSlide>

                </Swiper>
                <div className="swiper-pagination"></div>
            </div>
            {/* <Link className="custom-btn btn-codigo portafolio-btn" to="/project">
                <FormattedMessage
                    id='btn-more-projects'
                    defaultMessage='More projects'
                />
            </Link> */}
            <div className='portafolio-btn'>
                <Link to="/project">
                    <ButtomGet/>
                </Link>
            </div>
        </section>

    )
};
export default React.memo(Project);
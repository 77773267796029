import React from 'react';
import '../../pages/Project/ProjectPage.css'
import { Link } from 'react-router-dom';
import { ButtomGet } from '../ButtomGet/ButtomGet';
import techpro from '../../paperwork/certificates/techCertificate.pdf'
import java from '../../paperwork/certificates/İleri_Seviye_Java_Sertifika.pdf'
import asp_core  from '../../paperwork/certificates/ASP.NET_Core_MVC_Sertifika.pdf'
import asp_web from '../../paperwork/certificates/Asp.Net_Core_Web_API_Sertifika.pdf'
import github from '../../paperwork/certificates/Versiyon_Kontrolleri__Git_ve_GitHub_Sertifika.pdf'
import desing_pattern from '../../paperwork/certificates/Yazılım_Tasarım_Desenleri_Sertifika.pdf'
import react from '../../paperwork/certificates/REACT_ile_Web_Programcılığı_Sertifika.pdf'
import jenkins from '../../paperwork/certificates/DevOps_Çözümleri_(Jenkins)_Sertifika.pdf'
import API_test from '../../paperwork/certificates/API_ve_API_Testi_Sertifika.pdf'
import kotlin from '../../paperwork/certificates/Kotlin_ile_Android_Mobil_Uygulama_Geliştirme_İleri_Seviye_Sertifika.pdf'
import Azure from '../../paperwork/certificates/Microsoft_Azure_DevOps_Sertifika.pdf'
import JQuery from '../../paperwork/certificates/Projeler_ile_jQuery_Sertifika.pdf'
import BTK_github from '../../paperwork/certificates/Versiyon_Kontrolleri__Git_ve_GitHub_Sertifika.pdf'

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

/* Img */
const proyectImg = require.context('../../img/certificates', true);

const Certificate = () => {
    return (
        <section className="proyectos" id="cetificate">
            <h2 className="heading">
                <FormattedMessage
                    id='Certificates'
                    defaultMessage='Certificates'
                />
            </h2>
            <div className="proyect-site" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <Swiper
                    spaceBetween={30}
                    loop={true}
                    grabCursor={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    className='proyectos-slider mySwiper'
                >


                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./techprocourseback.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='techprocourseback'
                        defaultMessage='TECHPROEDUCATİON COURSE'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='techprocourseback1'
                        defaultMessage='TECHPROEDUCATİON COURSE'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> TECHPROEDUCATİON COURSE
                            </p>
                            <a href="../../paperwork/certificates/techCertificate.pdf" className="custom-btn btn"download="techCertificate.pdf"  target="_blank" rel="noopener noreferrer"><span> <FormattedMessage
                    id='Download'
                    defaultMessage='Download'
                />          
                </span></a>
                            <a href="https://lms.techproeducation.com/course/index.php?categoryid=15" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 

                    
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./techprocourse.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='techprocourse'
                        defaultMessage='TECHPROEDUCATİON COURSE'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='techprocourse1'
                        defaultMessage='TECHPROEDUCATİON COURSE'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> TECHPROEDUCATİON COURSE
                            </p>

                    <a href={techpro} target="_blank" rel="noopener noreferrer" download="techCertificate.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>


                            <a href="https://lms.techproeducation.com/course/index.php?categoryid=15" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 


                   <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./javas.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='advancedJava'
                        defaultMessage='Advanced JAVA'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='AdvancedJava1'
                        defaultMessage='Advanced JAVA'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                           
                  <a href={java} target="_blank" rel="noopener noreferrer" download="java.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/ileri-seviye-java-9353" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide> 
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./aspdotnetcoremvc.png`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='aspdotnetcoremvc'
                        defaultMessage='ASP.NET_Core_MVC'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='aspdotnetcoremvc1'
                        defaultMessage='ASP.NET_Core_MVC'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                           
                <a href={asp_core} target="_blank" rel="noopener noreferrer" download="asp_core.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/asp-net-core-mvc-25318" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide>


                     <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./asp.net.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='aspdotnet'
                        defaultMessage='ASP.NET'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='aspdotnet'
                        defaultMessage='ASP.NET'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                            <a href={asp_web} target="_blank" rel="noopener noreferrer" download="asp_web.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/asp-net-core-web-api-23993" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./github.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='gitHub'
                        defaultMessage='GitHub'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='gitHub1'
                        defaultMessage='GitHub'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> Linkedin Learning
                            </p>
                            <a href={github} target="_blank" rel="noopener noreferrer" download="github.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.linkedin.com/learning/learning-git-and-github-2021/travel-the-multiverse-with-git-and-github" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide>


                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./yazılımdeseni.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='yazılımdeseni'
                        defaultMessage='Yazılım Deseni'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='yazılımdeseni'
                        defaultMessage='Yazılım Deseni'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                            <a href={desing_pattern} target="_blank" rel="noopener noreferrer" download="desing_pattern.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/yazilim-tasarim-desenleri-12150" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./reactjs.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='reactjs'
                        defaultMessage='React.js'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='reactjs1'
                        defaultMessage='React.js'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                            <a href={react} target="_blank" rel="noopener noreferrer" download="react.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/react-ile-web-programciligi-10035" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./devops.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='jenkins'
                        defaultMessage='Jenkins'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='Jenkins1'
                        defaultMessage='Jenkins'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                            <a href={jenkins} target="_blank" rel="noopener noreferrer" download="jinkins.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/devops-cozumleri-jenkins-21323" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                />  </a>
                        </div>
                    </SwiperSlide>



                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./reactjs.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>
                                <FormattedMessage
                        id='Adobe_XD'
                        defaultMessage='Adobe_XD'/>
                    </h3>
                            <p>
                            <FormattedMessage
                        id='Adobe_XD'
                        defaultMessage='Adobe_XD'
                    />
                            </p>
                            <p className="tecnologias">
                                <span>-</span> BTK Akademi
                            </p>
                            <a href={react} target="_blank" rel="noopener noreferrer" download="react.pdf" className="custom-btn btn">
                         <FormattedMessage
                            id='Download'
                            defaultMessage='Download'
                         />
                    </a>
                            <a href="https://www.btkakademi.gov.tr/portal/course/react-ile-web-programciligi-10035" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer"><FormattedMessage
                    id='Review'
                    defaultMessage='Review'
                /></a>
                        </div>
                    </SwiperSlide>

                   
                </Swiper>

                
                <div className="swiper-pagination"></div>
            </div>
            {/* <Link className="custom-btn btn-codigo portafolio-btn" to="/project">
                <FormattedMessage
                    id='btn-more-projects'
                    defaultMessage='More projects'
                />
            </Link> 
            <div className='portafolio-btn'>
                <Link to="/Certificate">
                    <ButtomGet/>
                </Link>
            </div>*/}
        </section>

    )
};
export default React.memo(Certificate);
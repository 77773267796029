import React from 'react';
import './AboutPage.css'

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import trresume from '../../paperwork/resume/tr-resume.pdf';
import gresume from '../../paperwork/resume/g-resume.pdf';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */
import imgabout from '../../img/home.gif';

const About = () => {

  function readMore() {
    let btnHide = document.querySelector("#btn-hide");
    let parrafoActive = document.querySelector(".parrafo-active");

    parrafoActive.classList.toggle("show");

    if (parrafoActive.classList.contains("show")) {
      btnHide.innerHTML = "↑";
    } else {
      btnHide.innerHTML = "Read more";
    }
  }

  return (
    <div>

      <HeaderPage />

      <ParticleBackground />

      <main>
        <section className="sobre-mi-seccion " id="sobre-mi">
          <div className="sobre-mi-container ">
            <div className="sobre-mi-img-container">
              <img src={imgabout} alt="" className="sobre-mi-img" />
              
              <div className='btns_positions'>
                    <a href={trresume} target="_blank" rel="noopener noreferrer" download="cv.pdf" className="btn-codigo cv buttonDownload btn-p1">
                         <FormattedMessage
                            id='btn-tr-Resume'
                            defaultMessage='Turkey Resume'
                         />
                    </a>
                    <a href={gresume} target="_blank" rel="noopener noreferrer" download="cv.pdf" className="btn-codigo cv buttonDownload btn-p2">
                         <FormattedMessage
                            id='btn-tr-Resume'
                            defaultMessage='Global Resume'
                         />
                    </a>
                    </div>

           
            </div>
           
            <div className="sobre-mi-info">
              <p>
                <FormattedMessage
                  id='about-info-1'
                  defaultMessage="   With 3 years of experience, I have been actively involved in the software development industry as a dynamic, open-minded, and passionate developer. My strong interest in technology has allowed me to continuously enhance my skills through participation in courses and projects. I hold advanced-level certificates in Backend Java Development and Frontend React Development from Techproeducation Bootcamp, as well as certifications in Asp.net Core and Microsoft Azure from BTK Academy."
                />
              </p>

              <div className="hide parrafo-active">
                <p>
                  <FormattedMessage
                    id='about-info-2'
                    defaultMessage=' During my tenure as a Full Stack Java Developer, I have contributed to projects such as lets rental car and gaming pro market. I possess comprehensive knowledge in Java, Spring Framework, Microservices, Postgresql, MongoDB, React.js, Software Architecture, and other relevant topics. Additionally, I have hands-on experience with Asp.net Core and Microsoft Azure technologies. I strive to stay updated with the latest advancements in these areas and continuously improve my expertise. I am determined to progress in the software development industry and actively seek new opportunities to further enhance my skills. I am collaborative, adept at teamwork, and possess strong problem-solving abilities.'
                  />
                </p>
              </div>
              <div className="hide parrafo-active">
                <p>
                  <FormattedMessage
                    id='about-info-2-a'
                    defaultMessage=' During my tenure as a Full Stack Java Developer, I have contributed to projects such as lets rental car and gaming pro market. I possess comprehensive knowledge in Java, Spring Framework, Microservices, Postgresql, MongoDB, React.js, Software Architecture, and other relevant topics. Additionally, I have hands-on experience with Asp.net Core and Microsoft Azure technologies. I strive to stay updated with the latest advancements in these areas and continuously improve my expertise. I am determined to progress in the software development industry and actively seek new opportunities to further enhance my skills. I am collaborative, adept at teamwork, and possess strong problem-solving abilities.'
                  />
                </p>
              </div>

              <div className="btn-info">
                <div className="custom-btn btn-codigo" id="btn-hide" onClick={readMore}><span>Read more</span></div>
              </div>
            </div>

          </div>

          <div className="skill-seccion">
            <h1 className="heading">Skills</h1>
            <div className="skill-container">
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="HTML" className="skills-img icon-li" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg" title="HTML5" />
                <h2 className="skill-name">HTML</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-1'
                    defaultMessage='More than a year of professional experience in this language, allows me to perform without problems in the industry.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-up" data-aos-delay="300">
                <img alt="CSS" className="skills-img icon-li" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg" title="CSS3" />
                <h2 className="skill-name">CSS</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-2'
                    defaultMessage='More than a year of work experience in this language, I use it daily to develop web designs and interfaces.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="Js" className="skills-img icon-li" src="https://logosdownload.com/logo/javascript-logo-big.png" title="Java Script" />
                <h2 className="skill-name">Javascript</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-3'
                    defaultMessage='A year of experience using it in the front-end and back-end branch, allowed me to familiarize myself with the language, and include it in my work projects.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Sass" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" title="Sass" />
                <h2 className="skill-name">Sass</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-4'
                    defaultMessage='One year using this preprocessor in the professional field, I include it in many of my projects since it allows me a better structured code.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-down" data-aos-delay="300">
                <img alt="Bootstrap" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" title="Bootstrap" />
                <h2 className="skill-name">Bootstrap</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-5'
                    defaultMessage='Less than a year using this framework, even so, I used it in countless work projects, which allowed me to get to know it for the most part.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-down" data-aos-delay="300">
                <img alt="Tailwindcss" className="skills-img" src="https://static-00.iconduck.com/assets.00/tailwind-css-icon-2048x1229-u8dzt4uh.png" title="Tailwindcss" />
                <h2 className="skill-name">Tailwindcss</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-5'
                    defaultMessage='Less than a year using this framework, even so, I used it in countless work projects, which allowed me to get to know it for the most part.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="jQuery" className="skills-img" src="https://seeklogo.com/images/J/jquery-logo-CFE6ECE363-seeklogo.com.png" title="jQuery" />
                <h2 className="skill-name">jQuery</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-6'
                    defaultMessage='A year using this bookstore in the personal and work environment, it is one of the bookstores that I use and manipulate the most.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="React.js" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" title="React.Js" />
                <h2 className="skill-name">ReactJs</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-7'
                    defaultMessage='More than a year using this JavaScript framework, carrying out multiple personal and work projects.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
                <img alt="Asp.Net" className="skills-img" src="https://logodix.com/logo/1796970.png" title="Asp.Net" />
                <h2 className="skill-name">Asp.Net</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-7'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-up" data-aos-delay="300">
                <img alt="nodejs" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" title="Java" />
                <h2 className="skill-name">Java</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-8'
                    defaultMessage='It is the cross-platform runtime environment that I use to make my web applications scalable.'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="C#" className="skills-img" src="https://seeklogo.com/images/C/c-logo-A44DB3D53C-seeklogo.com.png" title="C#" />
                <h2 className="skill-name">C#</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-9'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Spring Boot" className="skills-img" src="https://images.contentstack.io/v3/assets/blt39790b633ee0d5a7/blt4f779d1b4fd496c5/647a2f42d88bb100a5216601/spring.webp" title="Spring Boot" />
                <h2 className="skill-name">Spring Boot</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-10'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Hibernate" className="skills-img" src="https://cdn.worldvectorlogo.com/logos/hibernate.svg" title="Hibernate" />
                <h2 className="skill-name">Hibernate</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-11'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Maven" className="skills-img" src="https://www.svgrepo.com/show/353400/apache.svg" title="Maven" />
                <h2 className="skill-name">Maven</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-12'
                    defaultMessage='...'
                  />
                </p>
              </div>
             
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="PsostgreSQL" className="skills-img" src="https://static.fragment.co.jp/wp-content/uploads/2016/01/20151202194604-2.png" title="PsostgreSQL" />
                <h2 className="skill-name">PsostgreSQL</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-13'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="MySQL" className="skills-img" src="https://cdn-icons-png.flaticon.com/512/528/528260.png" title="MySQL" />
                <h2 className="skill-name">MySQL</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-14'
                    defaultMessage='...'
                  />
                </p>
              </div> 
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Microsoft SQL Server" className="skills-img" src="https://uploads-ssl.webflow.com/62d5695206896eeec18fc636/63e6a9c0ac8f176d1d339210_sql-logo.png" title="Microsoft SQL Server" />
                <h2 className="skill-name">Microsoft SQL Server</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-15'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Mongodb" className="skills-img" src="https://www.svgrepo.com/download/331488/mongodb.svg" title="Mongodb" />
                <h2 className="skill-name">Mongodb</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-16'
                    defaultMessage='...'
                  />
                </p>
              </div>
              
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="AWS" className="skills-img" src="https://uploads-ssl.webflow.com/619811f15cf1e46af063c233/620dc6482e585bbdb5feff64_aws-logo.png" title="AWS" />
                <h2 className="skill-name">AWS</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-17'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Docker" className="skills-img" src="https://www.docker.com/wp-content/uploads/2022/03/Moby-logo.png" title="Docker" />
                <h2 className="skill-name">Docker</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-18'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Intellij" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/2048px-IntelliJ_IDEA_Icon.svg.png" title="Intellij" />
                <h2 className="skill-name">Intellij</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-19'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Eclipse" className="skills-img" src="https://www.pinclipart.com/picdir/big/520-5207988_transparent-eclipse-clip-art-free-png-download.png" title="Eclipse" />
                <h2 className="skill-name">Eclipse</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-20'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Visual Studio Code" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Visual_Studio_Code_1.18_icon.svg/1200px-Visual_Studio_Code_1.18_icon.svg.png" title="Visual Studio Code" />
                <h2 className="skill-name">Visual Studio Code</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-21'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="NetBeans" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Apache_NetBeans_Logo.svg/444px-Apache_NetBeans_Logo.svg.png" title="NetBeans" />
                <h2 className="skill-name">NetBeans</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-22'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Spring Tools 4" className="skills-img" src="https://spring.io/img/projects/spring-tool.svg" title="Spring Tools 4" />
                <h2 className="skill-name">Spring Tools 4</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-23'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="GitHub" className="skills-img" src="https://pngimg.com/d/github_PNG90.png" title="GitHub" />
                <h2 className="skill-name">GitHub</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-24'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Photoshop" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/2101px-Adobe_Photoshop_CC_icon.svg.png" title="Photoshop" />
                <h2 className="skill-name">Photoshop</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-25'
                    defaultMessage='...'
                  />
                </p>
              </div>
              <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
                <img alt="Adobe XD" className="skills-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/1200px-Adobe_XD_CC_icon.svg.png" title="Adobe XD" />
                <h2 className="skill-name">Adobe XD</h2>
                <p className="skill-info">
                  <FormattedMessage
                    id='about-skills-26'
                    defaultMessage='...'
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <ScrollToTop />

      <Footer />

    </div>
  )
}
export default About;
import React, { useState } from 'react'
import './ProjectPage.css';

/* Modal */
import Modal from "./Modal";

/* React router */
import { NavLink } from 'react-router-dom';

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';


/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Img */
const proyectsImgApp = require.context('../../img', true);

function ProjectApp() {
    const [estadoModal18, cambiarEstadoModal18] = useState(false);
    const [estadoModal17, cambiarEstadoModal17] = useState(false);
    const [estadoModal16, cambiarEstadoModal16] = useState(false);
    const [estadoModal15, cambiarEstadoModal15] = useState(false);
    const [estadoModal14, cambiarEstadoModal14] = useState(false);
    const [estadoModal13, cambiarEstadoModal13] = useState(false);
    const [estadoModal12, cambiarEstadoModal12] = useState(false);
    const [estadoModal11, cambiarEstadoModal11] = useState(false);
    const [estadoModal10, cambiarEstadoModal10] = useState(false);
    const [estadoModal9, cambiarEstadoModal9] = useState(false);
    const [estadoModal8, cambiarEstadoModal8] = useState(false);
    const [estadoModal7, cambiarEstadoModal7] = useState(false);
    const [estadoModal6, cambiarEstadoModal6] = useState(false);
    const [estadoModal5, cambiarEstadoModal5] = useState(false);
    const [estadoModal4, cambiarEstadoModal4] = useState(false);
    const [estadoModal3, cambiarEstadoModal3] = useState(false);
    const [estadoModal2, cambiarEstadoModal2] = useState(false);
    const [estadoModal1, cambiarEstadoModal1] = useState(false);

    return (
        <div>

            <HeaderPage />

            <ParticleBackground />

            <main>
                <section className="proyectos mas-proyect" id="proyectos">
                    <h1 className="heading">
                        <FormattedMessage
                            id='projects'
                            defaultMessage='Projects'
                        />
                    </h1>
                    <nav className="navbar nav-proj">
                        <NavLink to="/project/" offset={-150} duration={500}>
                            <FormattedMessage
                                id='site-web'
                                defaultMessage='React.Js'
                            />
                        </NavLink>
                        <NavLink to="/project/app" offset={-150} duration={500}>
                            Java BackEnd
                        </NavLink>
                        <NavLink to="/project/game" offset={-150} duration={500}>
                            <FormattedMessage
                                id='games'
                                defaultMessage='C# (Asp.Net)'
                            />
                        </NavLink>
                    </nav>
                </section>
                <section className="projects__grid apps">
                    <div className="projects__item">
                        <a onClick={() => cambiarEstadoModal18(!estadoModal18)}>
                            <img src={proyectsImgApp(`./myarena.png`)} alt="" className="projects__img" />
                        </a>
                    </div>
                    <div className="projects__item">
                        <a onClick={() => cambiarEstadoModal17(!estadoModal17)}>
                            <img src={proyectsImgApp(`./gpm.jpg`)} alt="" className="projects__img" />
                        </a>
                    </div>
                    <div className="projects__item">
                        <a onClick={() => cambiarEstadoModal16(!estadoModal16)}>
                            <img src={proyectsImgApp(`./lestrentalcarbackend.jpg`)} alt="" className="projects__img" />
                        </a>
                    </div>
                </section>
            </main>
            <Modal
                estado={estadoModal18}
                cambiarEstado={cambiarEstadoModal18}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImgApp(`./myarena.png`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                My Arena
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsApp-info-18-p1'
                                    defaultMessage='Being a futsal field rental application, MyArena offers an easy way to rent football fields. Football field rental involves individuals or teams renting the field for a specific period.

                                    Users can find suitable football fields in their vicinity, make reservations, and complete the rental process online through the application. This eliminates the need to physically visit or call the field to rent it, enabling users to easily book the field for their desired date and time.
                                    
                                    '
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsApp-info-18-p2'
                                    defaultMessage='Football field rental is preferred for various purposes, such as organizing friendly and enjoyable matches among friends, conducting training sessions for amateur teams, or hosting official matches. MyArena application provides users with different field options, reservation convenience, payment methods, and customer support, making the rental process more accessible and enjoyable.'
                                />
                            </p>
                            <div className="eins-modal-text-2">
                                <span></span> <a href="" target="_blank"></a>
                            </div>
                            <div className="eins-modal-text-3">
                                <span>
                                    <FormattedMessage
                                        id='projects-tec'
                                        defaultMessage='Used technology:'
                                    />
                                </span>
                                <div className="eins-modal-tec-2">
                                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" alt="Java" />
                                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" alt="PostgreSQL" />
                                    <img src="https://icon-library.com/images/hibernate-icon/hibernate-icon-0.jpg" alt="Hibernate" />
                                    <img src="https://javapro.ir/uploadfile/file_portal/site_2237_web/file_portal_end/%D8%A8%D8%AE%D8%B4-%D9%85%D8%AD%D8%AA%D9%88%D8%A7%DB%8C%DB%8C-%D8%B3%D8%A7%DB%8C%D8%AA/%D9%88%D8%A8%D9%84%D8%A7%DA%AF/%D9%85%D9%82%D8%A7%D9%84%D8%A7%D8%AA-%D8%AC%D8%A7%D9%88%D8%A7/4.png" alt="Maven" />
                                    <img src="https://www.svgrepo.com/show/374111/swagger.svg" alt="Swagger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                estado={estadoModal17}
                cambiarEstado={cambiarEstadoModal17}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImgApp(`./gpm.jpg`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                Gaming Pro Market
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsApp-info-17-p2'
                                    defaultMessage='Here, all game-related products are added by companies and sold on the e-commerce site.'
                                />
                            </p>
                            <div className="eins-modal-text-2">
                                <span>Link:</span> <a href="https://www.gamingpromarket.com/" target="_blank">https://www.gamingpromarket.com/</a>
                            </div>
                            <div className="eins-modal-text-3">
                                <span>
                                    <FormattedMessage
                                        id='projects-tec'
                                        defaultMessage='Used technology:'
                                    />
                                </span>
                                <div className="eins-modal-tec">
                                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" alt="Java" />
                                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" alt="PostgreSQL" />
                                    <img src="https://icon-library.com/images/hibernate-icon/hibernate-icon-0.jpg" alt="Hibernate" />
                                    <img src="https://javapro.ir/uploadfile/file_portal/site_2237_web/file_portal_end/%D8%A8%D8%AE%D8%B4-%D9%85%D8%AD%D8%AA%D9%88%D8%A7%DB%8C%DB%8C-%D8%B3%D8%A7%DB%8C%D8%AA/%D9%88%D8%A8%D9%84%D8%A7%DA%AF/%D9%85%D9%82%D8%A7%D9%84%D8%A7%D8%AA-%D8%AC%D8%A7%D9%88%D8%A7/4.png" alt="Maven" />
                                    <img src="https://www.svgrepo.com/show/374111/swagger.svg" alt="Swagger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                estado={estadoModal16}
                cambiarEstado={cambiarEstadoModal16}
            >
                <div className="content-modal">
                    <div className="pw-content">
                        <div className="eins-modal-preview"><img src={proyectsImgApp(`./lestrentalcarbackend.jpg`)} alt="" /></div>
                        <div className="eins-modal-text">
                            <p>
                                Let's Rental Car
                            </p>
                            <p>
                                <FormattedMessage
                                    id='projectsApp-info-16-p1'
                                    defaultMessage='Car rental is of significant importance in modern societies and is preferred for various reasons. Considering these aspects, we can explain the services provided by Lestrentalcar and the car rental process:'
                                />
                            </p>
                         
                            <div className="eins-modal-text-2">
                                <span>Link:</span> <a href="https://www.letsrentalcar.com/" target="_blank">https://www.letsrentalcar.com/</a>
                            </div>
                            <div className="eins-modal-text-3">
                                <span>
                                    <FormattedMessage
                                        id='projects-tec'
                                        defaultMessage='Used technology:'
                                    />
                                </span>
                                <div className="eins-modal-tec">
                                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" alt="Java" />
                                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" alt="PostgreSQL" />
                                    <img src="https://icon-library.com/images/hibernate-icon/hibernate-icon-0.jpg" alt="Hibernate" />
                                    <img src="https://javapro.ir/uploadfile/file_portal/site_2237_web/file_portal_end/%D8%A8%D8%AE%D8%B4-%D9%85%D8%AD%D8%AA%D9%88%D8%A7%DB%8C%DB%8C-%D8%B3%D8%A7%DB%8C%D8%AA/%D9%88%D8%A8%D9%84%D8%A7%DA%AF/%D9%85%D9%82%D8%A7%D9%84%D8%A7%D8%AA-%D8%AC%D8%A7%D9%88%D8%A7/4.png" alt="Maven" />
                                    <img src="https://www.svgrepo.com/show/374111/swagger.svg" alt="Swagger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            
            <ScrollToTop />

         <Footer />
        </div>
    ) 
}
  
export default ProjectApp

import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function ParticleHeaderBg() {
    const particlesInit = async (main) => {
        console.log(main);
    
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
      };
    
      const particlesLoaded = (container) => {
        console.log(container);
      };
      return (
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          className="particles-2-css"
      options={{
        
        background: {
          position: "20% 20%",
          repeat: "no-repeat",
          size: "cover",
          color: "#102429"
        },
        fullScreen: {
          enable: false
        },
        interactivity: {
          
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 10,
              divs: {
                distance: 200,
                duration: 0.4,
                mix: false,
                selectors: [],
              },
            },
            grab: {
              distance: 400,
            },
            repulse: {
              divs: {
                distance: 200,
                duration: 0.4,
                factor: 100,
                speed: 1,
                maxSpeed: 50,
                easing: "ease-out-quad",
                selectors: [],
              },
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: {
              value: "#102429",
            },
            distance: 150,
            opacity: 0.4,
          },
          move: {
            attract: {
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            enable: true,
            outModes: {
              bottom: "out",
              left: "out",
              right: "out",
              top: "out",
            },
          },
          number: {
            density: {
              enable: true,
            },
            value: 24,
          },
          opacity: {
            random: {
              enable: true,
            },
            value: {
              min: 0.1,
              max: 1,
            },
            animation: {
              enable: true,
              speed: 1,
              minimumValue: 0.2,
            },
          },
          rotate: {
            random: {
              enable: true,
            },
            animation: {
              enable: true,
              speed: 3,
            },
            direction: "random",
          },
          shape: {
            options: {
              character: {
                fill: false,
                font: "Verdana",
                style: "",
                value: "*",
                weight: "400",
              },
              char: {
                fill: false,
                font: "Verdana",
                style: "",
                value: "*",
                weight: "400",
              },
              polygon: {
                sides: 3,
              },
              star: {
                sides: 3,
              },
              image: [
                {
                  src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://i0.wp.com/ieee.ku.edu.tr/wp-content/uploads/2016/03/css-logo.png?ssl=1",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://logosdownload.com/logo/javascript-logo-big.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://seeklogo.com/images/J/jquery-logo-CFE6ECE363-seeklogo.com.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://seeklogo.com/images/1/net-logo-681E247422-seeklogo.com.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://www.svgrepo.com/download/331488/mongodb.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://uploads-ssl.webflow.com/619811f15cf1e46af063c233/620dc6482e585bbdb5feff64_aws-logo.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://seeklogo.com/images/C/c-logo-A44DB3D53C-seeklogo.com.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://images.contentstack.io/v3/assets/blt39790b633ee0d5a7/blt4f779d1b4fd496c5/647a2f42d88bb100a5216601/spring.webp",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://play-lh.googleusercontent.com/Gwj_E5u_VCKXCM2JuedvsOnTN4REq_m68RKB6NQM5X4kT6mpHrqHP27uoRp6B7QX-3w",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Apache_Feather_Logo.svg/1200px-Apache_Feather_Logo.svg.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/993px-Postgresql_elephant.svg.png",
                  width: 10,
                  height: 10,
                },
              
                {
                  src: "https://cdn-icons-png.flaticon.com/512/5968/5968313.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://uploads-ssl.webflow.com/62d5695206896eeec18fc636/63e6a9c0ac8f176d1d339210_sql-logo.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://www.svgrepo.com/download/331488/mongodb.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://www.docker.com/wp-content/uploads/2022/03/Moby-logo.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/2048px-IntelliJ_IDEA_Icon.svg.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://www.pinclipart.com/picdir/big/520-5207988_transparent-eclipse-clip-art-free-png-download.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/visual-studio-code-icon.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Apache_NetBeans_Logo.svg/444px-Apache_NetBeans_Logo.svg.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://spring.io/img/projects/spring-tool.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://pngimg.com/d/github_PNG90.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/2101px-Adobe_Photoshop_CC_icon.svg.png",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
                  width: 10,
                  height: 10,
                },
                {
                  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/1200px-Adobe_XD_CC_icon.svg.png",
                  width: 10,
                  height: 10,
                },
               ],
            },
            type: "image",
          },
          size: {
            value: 50,
            animation: {
              speed: 40,
              minimumValue: 0.1,
            },
          },
          stroke: {
            width: "100px",
            color: {
              value: "#9679",
              value: "#9679",
              animation: {
                h: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  decay: 0,
                  sync: true,
                },
                s: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  decay: 0,
                  sync: true,
                },
                l: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  decay: 0,
                  sync: true,
                },
              },
            },
          },
        },
      }}
    ></Particles>
  );
}
